import { createActions, handleActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = [];


export const actions = createActions ({
	[ actionTypes.ENQUEUE_SNACKBAR ]: ( message, options = {} ) => ({
		message,
		options: {
			...options,
			key: options.key || new Date().getTime() + Math.random()
		}
	}),
	[ actionTypes.CLOSE_SNACKBAR ]: key => ({ key, dismissAll: key === undefined || key === false }),
	[ actionTypes.REMOVE_SNACKBAR ]: key => key
});

export default handleActions (
	{
		[ actionTypes.ENQUEUE_SNACKBAR ]: ( state, { payload: snakbar } ) => [ ...state, { ...snakbar, key: snakbar.options.key } ],
		[ actionTypes.CLOSE_SNACKBAR ]: ( state, { payload: { key, dismissAll } } ) => state.map (
			snakbar => ( dismissAll || snakbar.key === key ) ? { ...snakbar, dismissed: true } : snakbar
		),
		[ actionTypes.REMOVE_SNACKBAR ]: ( state, { payload: key } ) => state.filter (
			snakbar => snakbar.key !== key
		)
	},
	defaultState
);
