// transform-origin

export default function getTemplates ( ymaps )
{
	return {
		trackPoint: ymaps.templateLayoutFactory.createClass (
			`<svg style="color:$[properties.color];transform:rotate($[properties.rotation]deg)" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 18L7 0L14 18L7 15L0 18Z" fill="currentColor" />
				<path d="M0.897632 17.0713L7 1.37952L13.1024 17.0713L7.19696 14.5404L7 14.456L6.80304 14.5404L0.897632 17.0713Z" stroke="white" stroke-opacity="1" stroke-width="0.5" />
			</svg>`
		),
		endPoint: ymaps.templateLayoutFactory.createClass (
			`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g filter="url(#filter0_d)">
					<circle cx="8" cy="8" r="6" fill="$[properties.color]"/>
					<circle cx="8" cy="8" r="5.5" stroke="white"/>
				</g>
				<defs>
					<filter id="filter0_d" x="0" y="0" width="16" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
						<feFlood flood-opacity="0" result="BackgroundImageFix"/>
						<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
						<feOffset/>
						<feGaussianBlur stdDeviation="1"/>
						<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
						<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
						<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
					</filter>
				</defs>
			</svg>`
		)
	};
}
