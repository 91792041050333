import { createActions, handleActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = {
	id: false,
	name: '',
	authorized: false,
	waiter: false
};


export const actions = createActions ({
	[ actionTypes.LOGIN ]: user => ({ user }),
	[ actionTypes.LOGOUT ]: null,
	[ actionTypes.USER_REQUEST ]: null,
	[ actionTypes.USER ]: user => ({ ...user })
});

export default handleActions (
	{
		[ actionTypes.LOGIN ]: () => ({ authorized: true }),
		[ actionTypes.LOGOUT ]: () => defaultState,
		[ actionTypes.USER_REQUEST ]: state => ({ ...state, waiter: true }),
		[ actionTypes.USER ]: ( { authorized }, { payload } ) => ({ ...defaultState, ...payload, authorized, waiter: false })
	},
	defaultState
);
