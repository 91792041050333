import { createActions, handleActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = {
	drivers: [],
	selected: false,
	waiter: false,
	error: false
};


export const actions = createActions ({
	[ actionTypes.DRIVERS_REQUEST ]: null,
	[ actionTypes.DRIVERS ]: ( drivers = [], error = false ) => ({
		drivers: drivers.sort (
			( a, b ) => a.name.localeCompare ( b.name )
		),
		error
	}),
	[ actionTypes.SELECT_DRIVER ]: driverId => ({ driverId })
});

export default handleActions (
	{
		[ actionTypes.DRIVERS_REQUEST ]: state => ({ ...state, waiter: true }),
		[ actionTypes.DRIVERS ]: ( state, { payload: { drivers, error } } ) => ({ ...state, drivers, error, waiter: false }),
		[ actionTypes.SELECT_DRIVER ]: ( state, { payload: { driverId } } ) => ({ ...state, selected: driverId }),
		[ actionTypes.LOGOUT ]: () => defaultState
	},
	defaultState
);
