import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';


export default React.memo ( Waiter );

function Waiter ({ block })
{
	const classes = useStyles();

	return (
		<React.Fragment>
			{ !block &&
				<div className={ clsx ( classes.container, classes.animate ) } />
			}
			<div className={ clsx ( classes.container, block && classes.block ) }>
				<CircularProgress thickness={ 2 } size={ 70 } />
			</div>
		</React.Fragment>
	);
}


const useStyles = makeStyles ( theme => ({
	'@keyframes pulseAnimation': {
		from: { opacity: 0 },
		to: { opacity: 0.5 }
	},
	container: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1000
	},
	block: {
		position: 'static',
		height: 100
	},
	animate: {
		backgroundColor: '#FFF',
		animationName: '$pulseAnimation',
		animationDirection: 'alternate',
		animationDuration: '1s',
		animationIterationCount: 'infinite'
	}
}) );
