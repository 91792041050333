import { create } from 'apisauce';
import Reactotron from 'reactotron-react-js';
import * as schema from 'schema';
import moment from 'moment';
import api from 'api';

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const http = create ({ baseURL: '/api/v1/' });


http.addResponseTransform ( response => {
	const { data, ok, problem, status } = response;

	if ( !ok )
	{
		const  error = { ...data, problem, status },
			{ errors } = error;

		response.error = error;

		if ( errors )
		{
			Object.keys ( errors )
				.forEach ( name => {
					if ( Array.isArray ( errors[ name ] ) )
					{
						errors[ name ] = errors[ name ].join ( ' ' );
					}
				} );
		}
	}
} );

if ( development ) http.addMonitor ( Reactotron.apisauce );


export function setToken ( token )
{
	if ( token )
	{
		api.storage.set ( 'token', token );
		http.setHeaders ({ Authorization: `Bearer ${ token }` });
	}
	else
	{
		api.storage.remove ( 'token' );
		delete http.headers.Authorization;
	}
}

export function hasToken ()
{
	return !!http.headers[ 'X-Token' ];
}

export function auth ( phone )
{
	phone = '7' + ( phone || '' ).replace ( /\D/g, '' ).slice ( -10 );

	const storedDevice = api.storage.get ( 'device' ) || undefined;

	return http.post ( 'auth/requests', { phone, key: storedDevice } )
		.then ( getResultHandler() )
		.then ( ({ error, key: device }) => {
			if ( error ) return { error };

			if ( device !== storedDevice ) api.storage.set ( 'device', device );

			return { success: true };
		} )
		.catch ( error => ({ error }) );
}

export function confirm ( code )
{
	code = code.replace ( /\D/g, '' ).slice ( -4 );

	const device = api.storage.get ( 'device' ) || '';

	return http.post ( `auth/requests/${ device }/confirm`, { code } )
		.then ( getResultHandler() )
		.then ( ({ error, token }) => {
			setToken ( token || false );

			return error ? { error } : { success: true };
		} )
		.catch ( error => ({ error }) );
}

export function getUser ()
{
	return request (
		http.get ( 'me' ),
		({ data }) => ({ user: data }),
		schema.getUser.label ( 'getUser' )
	);
}

export function getDrivers ()
{
	return request (
		http.get ( 'drivers' ),
		({ data }) => ({ drivers: data }),
		schema.getDrivers
	);
}

export function getTracking ( user_id, date )
{
	user_id = String ( user_id );

	const from = ( date || moment() ).startOf ( 'day' ).format(),
		to = ( date || moment() ).endOf ( 'day' ).format();

	return request (
		http.post ( 'navigation/get-path', { user_id, from, to } ),
		({ data }) => ({ track: data }),
		schema.getTracking
	);
}

function request ( promise, handleResult, schema, handleError = error => ({ error: { ...error } }) )
{
	return promise.then ( getResultHandler ( handleResult ) )
		.then ( data => schema.validate ( data ) )
		.catch ( handleError );
}

// (fn|arr|str) => obj
function getResultHandler ( fn = ({ data }) => data )
{
	return ({ data, ok, problem, error }) => {
		if ( error ) throw error;

		if ( typeof fn === 'string' || Array.isArray ( fn ) )
		{
			const props = [].concat ( fn );

			fn = data => props.reduce (
				( res, prop ) => ({ ...res, [ prop ]: data[ prop ] }),
				{}
			);
		}

		return fn ( data );
	}
}
