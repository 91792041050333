import * as yup from 'yup';


export const
	// id = yup.number().required().positive().integer(),
	id = yup.string().required(),
	user = yup.object ({
		id,
		name: yup.string().required(),
		role: yup.string().required()
	}),
	getUser = yup.object ({ user }),
	driver = yup.object ({
		id,
		name: yup.string().required(),
		car: yup.string(),
		car_number: yup.string(),
		phone_number: yup.string()
	}),
	drivers = yup.array ( driver ).required().ensure(),
	getDrivers = yup.object ({ drivers }),
	trackPoint = yup.object ({
		id,
		lat: yup.number().required(),
		lon: yup.number().required(),
		created_at: yup.date().required()
	}),
	track = yup.array ( trackPoint ).ensure(),
	getTracking = yup.object ({ track });
