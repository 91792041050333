import { takeLatest, put } from 'redux-saga/effects';
import * as actionTypes from 'actionTypes';
import actions from 'actions';


export default function* error ()
{
	yield takeLatest ( actionTypes.ERROR, handleError );
}

export function* handleError ({ payload: { error: { message, status, problem }, runAlert } = {} })
{
	if ( status === 401 ) yield put ( actions.logout() );

	if ( runAlert )
	{
		yield put (
			actions.enqueueSnackbar (
				message || problem || 'Ошибка',
				{
					variant: 'error',
					key: runAlert === true ? undefined : runAlert
				}
			)
		);
	}
}
