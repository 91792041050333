import randomColor from 'randomcolor';
import md5 from 'blueimp-md5';


export function getFormikFieldErrorProps ( form, field )
{
	const { name } = field,
		{ touched, errors } = form,
		helperText = touched[ name ] && errors[ name ];

	return { helperText, error: !!helperText };
}

export function forChunk ( target, handler, step = 1, timeout = 0 )
{
	const timerRef = {};

	( function fn ( i = 0 )
	{
		timerRef.current = setTimeout (
			() => {
				const start = i * step,
					count = Math.min ( step, target.length - start );

				if ( count > 0 )
				{
					const chunk = target.slice ( start, start + count );

					handler ( chunk, i, target );

					fn ( i + 1 );
				}
			},
			timeout
		)
	} )();

	return () => clearTimeout ( timerRef.current );
}

export function getColorById ( id )
{
	return randomColor ({ seed: md5 ( id ).slice ( 0, 8 ), luminosity: 'bright' })
}
