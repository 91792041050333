const req = require.context (
	'./reducers',
	true,
	/\.\/((?!index)[A-Za-z0-9]+)$/
);


export default req.keys().reduce (
	( res, key ) => {
		const current = req ( key ).actions;

		return current ? { ...res, ...current } : res;
	},
	{}
);
