import { all, takeLatest, put } from 'redux-saga/effects';
import * as actionTypes from 'actionTypes';
import actions from 'actions';
import api from 'api';


export default function* auth ()
{
	yield all ([
		takeLatest ( actionTypes.LOGOUT, logout ),
		takeLatest ( actionTypes.LOGIN, login ),
		takeLatest ( actionTypes.USER_REQUEST, userRequest ),
		init()
	]);
}

export function logout ()
{
	api.web.setToken ( false );
}

export function* init ()
{
	const token = api.storage.get ( 'token' );

	api.web.setToken ( token );

	if ( token ) yield put ( actions.login() );

	yield put ( actions.init() );
}

function* login ()
{
	yield put ( actions.userRequest() );
	yield put ( actions.driversRequest() );
}

function* userRequest ()
{
	const { error, user } = yield api.web.getUser();

	if ( error ) yield put ( actions.error ( error, true ) );

	yield put ( actions.user ( user ) );
}
