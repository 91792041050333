import React, { useEffect } from 'react';
import { ZoomControl } from 'react-yandex-maps';
import { ToggleButton } from '@material-ui/lab';
import { TrackLine, TrackPoints, Waiter } from 'components';
import { useSelector, shallowEqual } from 'react-redux';
import getTemplates from './getTemplates';
import {
	useMainDrawer,
	MapWrapper,
	MapComponent as MlkMapComponent,
	MapOptions,
	Icon,
	MapMarginArea,
	useMapComponent,
	useMapOption,
	useCloseBalloonOnMapClick
} from 'mlk-ui-kit';

const initialOptions = { colors: true, 'track-points': true };


export default React.memo ( MapComponent );

function MapComponent ()
{
	const { waiter, track } = useSelector ( st => st.tracking, shallowEqual ),
		{ opened } = useMainDrawer(),
		{ templates, map, ymaps } = useMapComponent(),
		hideTrackPoints = !useMapOption ( 'track-points' );

	useCloseBalloonOnMapClick ( map );

	useEffect (
		() => {
			if ( map )
			{
				// Иначе точки не успевают попасть в geoObjects
				setTimeout (
					() => {
						const bounds = map.geoObjects.getBounds();

						if ( bounds ) map.setBounds ( bounds, { checkZoomRange: true, useMapMargin: true } );
					}
				);
			}
		},
		[ track, map ]
	);

	return (
		<MapWrapper>
			<MlkMapComponent {...{ getTemplates }}>
				<ZoomControl />
				<TrackLine />
				{ !!( templates && ymaps ) &&
					<TrackPoints hide={ hideTrackPoints } {...{ templates, ymaps }} />
				}
				<MapMarginArea panTo top={ 0 } right={ 0 } height="100%" width={ opened ? 320 : 0 } />
			</MlkMapComponent>
			<MapOptions {...{ initialOptions }}>
				<ToggleButton value="track-points">
					<Icon icon="track" size={ 18 } />
				</ToggleButton>
			</MapOptions>
			{ ( waiter || !map ) &&
				<Waiter />
			}
		</MapWrapper>
	);
}
