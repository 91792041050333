import React, { useState, useEffect } from 'react';
import { ObjectManager } from 'react-yandex-maps';
import { useSelector, shallowEqual } from 'react-redux';
import { getColorById } from 'functions';
import hash from 'object-hash';
const defaultFeatures = [];


export default React.memo ( TrackLine );

function TrackLine ()
{
	const [ features, setFeatures ] = useState ( defaultFeatures ),
		{ track, driverId } = useSelector ( st => st.tracking, shallowEqual ),
		strokeColor = getColorById ( driverId );

	useEffect (
		() => {
			const features = !track.length ? defaultFeatures : [{
				type: 'Feature',
				id: hash ( track ),
				options: {
					strokeWidth: 3,
					strokeColor
				},
				properties: {
					balloonContent: driverId
				},
				geometry: {
					type: 'LineString',
					coordinates: track.map (
						({ lat, lon }) => [ lat, lon ]
					)
				}
			}];

			setFeatures ( features );
		},
		[ track, strokeColor, driverId ]
	);

	if ( !features.length ) return null;

	return <ObjectManager {...{ features }} />;
}
