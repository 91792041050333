import { all, fork } from 'redux-saga/effects';

const req = require.context ( '.', true, /\.\/((?!index)[A-Za-z0-9]+)$/ ),
	sagas = req.keys().reduce (
		( res, key ) => {
			const current = req ( key ).default;

			return current ? [ ...res, current ] : res;
		},
		[]
	);


export default function* root ()
{
	yield all ( sagas.map ( fork ) );
}
