import { all, takeLatest, put, select } from 'redux-saga/effects';
import * as actionTypes from 'actionTypes';
import actions from 'actions';
import api from 'api';


export default function* tracking ()
{
	yield all ([
		takeLatest ( actionTypes.SELECT_DRIVER, selectDriver ),
		takeLatest ( actionTypes.TRACKING_SET_DATE, trackingSetDate ),
		takeLatest ( actionTypes.TRACKING_REQUEST, trackingRequest )
	]);
}

export function* selectDriver ({ payload: { driverId } })
{
	const { date } = yield select ( st => st.tracking );

	yield put ( actions.trackingRequest ( driverId, date ) );
}

export function* trackingSetDate ({ payload: date })
{
	const { driverId } = yield select ( st => st.tracking );

	if ( driverId !== false )
	{
		yield put ( actions.trackingRequest ( driverId, date ) );
	}
}

export function* trackingRequest ({ payload: { driverId, date } })
{
	const { drivers } = yield select ( st => st.drivers ),
		{ name } = drivers.find ( d => d.id === driverId ) || {},
		{ error, track } = yield api.web.getTracking ( driverId, date );

	if ( error ) yield put ( actions.error ( error, true ) );
	else
	{
		if ( !track.length ) yield put ( actions.enqueueSnackbar ( `Нет точек - ${ name }` ) );
		else
		{
			yield put ( actions.closeSnackbar() );
		}
	}

	yield put ( actions.tracking ( track, error ) );
}
