import React, { useState, useEffect } from 'react';
import { ObjectManager } from 'react-yandex-maps';
import { useSelector, shallowEqual } from 'react-redux';
import { forChunk, getColorById } from 'functions';


export default React.memo ( TrackPoints );

function TrackPoints ({ hide, templates, ymaps })
{
	const { solveInverseProblem } = ymaps.coordSystem.geo,
		{ track, driverId } = useSelector ( st => st.tracking, shallowEqual ),
		color = getColorById ( driverId ),
		[ features, setFeatures ] = useState ( undefined );

	useEffect (
		() => {
			if ( hide ) return;

			const features = track.slice().reverse().map (
					( { id, lat, lon, created_at }, i, arr ) => ({
						id,
						type: 'Feature',
						properties: {
							balloonContent: created_at,
							// iconContent: i + 1,
							color,
							rotation: ( i !== 0 && i !== arr.length - 1 ) &&
								90 - calcRotation (
									lat,
									lon,
									arr[ i + 1 ].lat,
									arr[ i + 1 ].lon,
									solveInverseProblem
								)
						},
						options: ( i === 0 || i === arr.length - 1 ) ? {
							zIndex: 2,
							iconColor: color,
							iconLayout: templates.endPoint,
							hideIconOnBalloonOpen: false,
							iconOffset: [ -8, -8 ],
							iconShape: {
								type: 'Circle',
								coordinates: [ 8, 8 ],
								radius: 6
							}
						} : {
							zIndex: 1,
							iconColor: color,
							iconLayout: templates.trackPoint,
							hideIconOnBalloonOpen: false,
							iconOffset: [ -7, -9 ],
							iconShape: {
								type: 'Circle',
								coordinates: [ 7, 9 ],
								radius: 7
							}
						},
						geometry: {
							type: 'Point',
							coordinates: [
								lat,
								lon
							]
						}
					})
				),
				start = features.length && features.shift(),
				stop = features.length && features.pop();

			if ( stop ) features.unshift ( stop );
			if ( start ) features.unshift ( start );

			setFeatures ( [] );

			return forChunk (
				features,
				chunk => setFeatures (
					current => current.concat ( chunk )
				),
				20
			);
		},
		[ track, color, hide, templates, solveInverseProblem ]
	);

	if ( !track.length || hide ) return null;

	return <ObjectManager {...{ features }} />;
}


function calcRotation ( lat, lon, lat2, lon2, solveInverseProblem )
{
	const [ y, x ] = solveInverseProblem (
			[ lat, lon ],
			[ lat2, lon2 ]
		).startDirection,
		rad = Math.atan2 ( y, x ),
		deg = rad / Math.PI * 180;

	return deg;
}
