import { createActions, handleActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = {
	track: [],
	driverId: false,
	date: undefined,
	waiter: false,
	error: false
};


export const actions = createActions ({
	[ actionTypes.TRACKING_REQUEST ]: ( driverId, date ) => ({ driverId, date }),
	[ actionTypes.TRACKING ]: ( track = [], error = false ) => ({ track, error }),
	[ actionTypes.TRACKING_SET_DATE ]: date => date
});

export default handleActions (
	{
		[ actionTypes.TRACKING_REQUEST ]: ( state, { payload: { driverId, date } } ) => ({ ...state, driverId, date, track: defaultState.track, waiter: true }),
		[ actionTypes.TRACKING ]: ( state, { payload: { track, error } } ) => ({ ...state, track, error, waiter: false }),
		[ actionTypes.TRACKING_SET_DATE ]: ( state, { payload: date } ) => ({ ...state, date }),
		[ actionTypes.LOGOUT ]: () => defaultState
	},
	defaultState
);
