import { useRef, useState, useCallback, useEffect } from 'react';
import reduxStore from 'reduxStore';
import actions from 'actions';
import api from 'api';
const defaultObj = {};


export function useRequest ( name, runAlert, onSuccess, onError )
{
	const [ waiters, setWaiters ] = useState ( new Set() ),
		[ response, setResponse ] = useState ( defaultObj ),
		[ error, setError ] = useState ( '' ),
		didUnmount = useRef ( false ),
		waitersRef = useRef ( waiters ),
		addWaiter = useCallback (
			waiter => setWaiters (
				waiters => new Set ( waiters.add ( waiter ) )
			),
			[]
		),
		removeWaiter = useCallback (
			waiter => setWaiters (
				waiters => {
					waiters.delete ( waiter );

					return new Set ( waiters );
				}
			),
			[]
		),
		getErrorHandler = useCallback (
			waiter => error => {
				setError ( error );
				setResponse ( defaultObj );

				reduxStore.dispatch ( actions.error ( error, runAlert ) );

				if ( onError ) onError ( error );

				removeWaiter ( waiter );

				return { error };
			},
			[ runAlert, setError, setResponse, onError, removeWaiter ]
		),
		request = useCallback (
			( ...args ) => {
				const waiter = new Date();

				addWaiter ( waiter );

				return api.web[ name ]( ...args ).then ( ({ error, ...res }) => {

					if ( didUnmount.current || !waitersRef.current.has ( waiter ) ) return { error: 'Rejected' };

					removeWaiter ( waiter );

					// eslint-disable-next-line no-throw-literal
					if ( error ) throw { ...error, name, args };
					else
					{
						setError ( '' );
						setResponse ( res );

						if ( onSuccess ) onSuccess ( res );
					}

					return { error, response: res, args, name };
				} )
				.catch ( getErrorHandler ( waiter ) );
			},
			[ name, addWaiter, removeWaiter, onSuccess, getErrorHandler ]
		);

		useEffect (
			() => {
				waitersRef.current = waiters;
			},
			[ waiters ]
		);

		useEffect (
			() => () => didUnmount.current = true,
			[]
		);

		useEffect (
			() => {
				if ( typeof runAlert === 'string' )
				{
					return () => reduxStore.dispatch ( actions.notistackClose ( runAlert ) );
				}
			},
			[ runAlert ]
		);

	return [ !!waiters.size, request, error, response ];
}
