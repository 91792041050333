import { createActions, handleActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = {
	init: false
};


export const actions = createActions ({
	[ actionTypes.INIT ]: null,
	[ actionTypes.ERROR ]: ( error, runAlert ) => ({ error, runAlert })
});

export default handleActions (
	{
		[ actionTypes.INIT ]: state => ({ ...state, init: true })
	},
	defaultState
);
