import { version } from '../../package.json';
import React, { useState, useCallback } from 'react';
import { AuthComponent, AuthLogo, AuthVersion, Waiter } from 'mlk-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'hooks';
import actions from 'actions';

const dev = process.env.NODE_ENV === 'development',
	initialValues = { phone: dev ? '0857654414' : '', code: dev ? '2369' : '' };


export default React.memo ( Auth );

function Auth ()
{
	const dispatch = useDispatch(),
		userWaiter = useSelector ( st => st.user.waiter ),
		[ confirm, setConfirm ] = useState ( false ),
		[ waiter, request ] = useRequest ( confirm ? 'confirm' : 'auth', true ), 
		onBack = useCallback (
			() => setConfirm ( false ),
			[]
		),
		onSubmit = useCallback (
			( { phone, code }, { setErrors } ) => {
				request ( confirm ? code : phone )
					.then ( ({ error }) => {
						if ( error )
						{
							if ( error.errors ) setErrors ( error.errors );
						}
						else
						{
							if ( !confirm )
							{
								setConfirm ( true );
							}
							else dispatch ( actions.login () );
						}
					} );
			},
			[ confirm, request, dispatch ]
		);

	return (
		<AuthComponent {...{ confirm, initialValues, onSubmit, onBack }}>
			<AuthLogo src="./logo.svg" alt="logo" />
			<AuthVersion>{ version }</AuthVersion>
			{ ( waiter || userWaiter ) &&
				<Waiter />
			}
		</AuthComponent>
	);
}
