import { takeLatest, put } from 'redux-saga/effects';
import * as actionTypes from 'actionTypes';
import actions from 'actions';
import api from 'api';


export default function* drivers ()
{
	yield takeLatest ( actionTypes.DRIVERS_REQUEST, driversRequest );
}

export function* driversRequest ()
{
	const { error, drivers } = yield api.web.getDrivers();

	if ( error ) yield put ( actions.error ( error, true ) );

	yield put ( actions.drivers ( drivers, error ) );
}
