import "moment/locale/ru";
import React, { useState, useCallback } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from '@material-ui/pickers';
import { DateBar } from 'mlk-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import actions from 'actions';
import moment from 'moment';
const TextFieldComponent = () => null;

moment.locale ( 'ru' );


export default React.memo ( Calendar );

function Calendar ({ children })
{
	const dispatch = useDispatch(),
		value = useSelector ( st => st.tracking.date ),
		[ open, setOpen ] = useState ( false ),
		onClick = useCallback (
			() => setOpen ( true ),
			[]
		),
		onClose = useCallback (
			() => setOpen ( false ),
			[]
		),
		onChange = useCallback (
			date => dispatch (
				actions.trackingSetDate ( date )
			),
			[ dispatch ]
		),
		onNext = useCallback (
			() => onChange (
				moment ( value ).add ( 1, 'days' )
			),
			[ value, onChange ]
		),
		onPrev = useCallback (
			() => onChange (
				moment ( value ).subtract ( 1, 'days' )
			),
			[ value, onChange ]
		);

	return (
		<MuiPickersUtilsProvider utils={ MomentUtils }>
			<DatePicker
				disableFuture
				cancelLabel="Отмена"
				variant="dialog"
				okLabel="ОК"
				{...{ value, open, TextFieldComponent, onClose, onChange }}
			/>
			<DateBar
				nextDisabled={ moment().startOf ( 'day' ).diff ( moment ( value ).startOf ( 'day' ), 'days' ) <= 0 }
				{...{ onClick, onPrev, onNext }}
			>
				{ moment ( value ).format ( 'DD MMMM' ) }
			</DateBar>
		</MuiPickersUtilsProvider>
	);
}
