import React, { useState, useCallback, useEffect } from 'react';
import { FormControl, OutlinedInput, InputLabel, InputAdornment, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, Checkbox, Collapse, Divider, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreVert, Search } from '@material-ui/icons';
import { Waiter } from 'components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useMainDrawer } from 'mlk-ui-kit';
import { getColorById } from 'functions';
import actions from 'actions';


export default React.memo ( Drivers );

function Drivers ()
{
	const classes = useStyles(),
		dispatch = useDispatch(),
		{ opened } = useMainDrawer(),
		[ open, setOpen ] = useState ( true ),
		[ search, setSearch ] = useState ( '' ),
		{ waiter, drivers, selected } = useSelector ( st => st.drivers, shallowEqual ),
		selectDriver = useCallback (
			id => dispatch ( actions.selectDriver ( id ) ),
			[ dispatch ]
		);

	useEffect (
		() => {
			if ( opened ) return;

			setTimeout (
				() => setSearch ( '' ),
				300
			);
		},
		[ opened ]
	);

	return (
		<React.Fragment>
			<List disablePadding className={ classes.root }>
				<ListItem>
					<FormControl fullWidth margin="dense" variant="outlined">
						<InputLabel className={ classes.label }>Найти водителя</InputLabel>
						<OutlinedInput
							className={ classes.outlinedInput }
							labelWidth={ 122 }
							variant="outlined"
							endAdornment={
								<InputAdornment className={ classes.adornment } position="end">
									<Search color="inherit" />
								</InputAdornment>
							}
							onChange={ e => setSearch ( e.target.value ) }
							value={ search }
						/>
					</FormControl>
				</ListItem>
				<Divider />
				<List disablePadding className={ classes.scroll }>
					<ListItem
						style={{ height: 59 }}
						divider
						button
						selected={ open }
						onClick={ () => setOpen ( open => !open ) }
					>
						{/*<ListItemIcon>
							<Checkbox
								disabled
								edge="start"
								color="primary"
								// checked={ id === selected }
								tabIndex={ -1 }
								disableRipple
							/>
						</ListItemIcon>*/}
						<ListItemText primary="Водители" />
						<ListItemSecondaryAction>
							<IconButton edge="end" size="medium">
								<MoreVert />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
					<Collapse in={ open } timeout="auto" unmountOnExit>
						<List dense disablePadding>
							{ drivers.filter ( ({ name }) => name.toLowerCase().includes ( search.toLowerCase() ) ).map (
								({ id, name, car, car_number, phone_number }) => (
									<ListItem
										key={ id }
										className={ classes.nested }
										button
										dense
										// selected={ id === selected }
										onClick={ () => selectDriver ( id ) }
									>
										<ListItemIcon>
											<Checkbox
												size="small"
												edge="start"
												color="primary"
												checked={ id === selected }
												tabIndex={ -1 }
												disableRipple
											/>
										</ListItemIcon>
										<div className={ classes.dot } style={{ backgroundColor: getColorById ( id ) }} />
										<ListItemText primary={ name } />
										<ListItemSecondaryAction>
											<Tooltip
												title={ `${ car } ${ car_number } +7${ phone_number }` }
												enterDelay={ 500 }
												leaveDelay={ 0 }
												placement="top"
												id={ String ( id ) }
											>
												<IconButton edge="end" size="small">
													<MoreVert fontSize="small" />
												</IconButton>
											</Tooltip>
										</ListItemSecondaryAction>
									</ListItem>
								)
							) }
						</List>
					</Collapse>
				</List>
				<Divider />
				<ListItem style={{ height: 59 }}>
					<ListItemText primary={ `Всего водителей: ${ drivers.length }` } />
				</ListItem>
			</List>
			{ waiter &&
				<Waiter />
			}
		</React.Fragment>
	);
}


const useStyles = makeStyles ( theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		overflow: 'hidden'
	},
	adornment: {
		color: theme.palette.action.active
	},
	label: {
		'&.MuiInputLabel-marginDense': {
			transform: 'translate(36px, 12px) scale(1)'
		},
		'&.Mui-focused, &.MuiFormLabel-filled': {
			transform: 'translate(14px, -6px) scale(0.75)'
		}
	},
	outlinedInput: {
		flexDirection: 'row-reverse',
		'&:hover .MuiInputAdornment-root .MuiSvgIcon-root': {
			color: theme.palette.text.primary
		},
		'&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root': {
			color: theme.palette.primary.main
		},
		'& input': {
			paddingLeft: theme.spacing ( 0.5 )
		}
	},
	scroll: {
		flex: 1,
		overflow: 'auto'
	},
	nested: {
		paddingLeft: theme.spacing ( 4 )
	},
	dot: {
		position: 'absolute',
		left: theme.spacing ( 8 ),
		width: 10,
		height: 10,
		borderRadius: '50%'
	}
}) );

// https://stackoverflow.com/questions/59654537/how-to-style-inputadornment-like-notched-outlinedinput
