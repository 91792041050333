export const
	INIT = 'INIT',
	ERROR = 'ERROR',
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
	USER_REQUEST = 'USER_REQUEST',
	USER = 'USER',
	DRIVERS_REQUEST = 'DRIVERS_REQUEST',
	DRIVERS = 'DRIVERS',
	SELECT_DRIVER = 'SELECT_DRIVER',
	TRACKING_REQUEST = 'TRACKING_REQUEST',
	TRACKING = 'TRACKING',
	TRACKING_SET_DATE = 'TRACKING_SET_DATE',
	ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR',
	CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',
	REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
