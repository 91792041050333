import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import actions from 'actions';


export default React.memo ( Snakbars );

function Snakbars ()
{
	const dispatch = useDispatch(),
		displayed = useRef ( [] ),
		snakbars = useSelector ( store => store.snakbars || [] ),
		{ enqueueSnackbar, closeSnackbar } = useSnackbar(),
		storeDisplayed = id => {
			displayed.current = [ ...displayed.current, id ];
		},
		removeDisplayed = id => {
			displayed.current = displayed.current.filter ( key => id !== key );
		};

	useEffect (
		() => {
			snakbars.forEach ( ({ key, message, options = {}, dismissed = false }) => {
				if ( dismissed )
				{
					// dismiss snackbar using notistack
					closeSnackbar ( key );
					return;
				}

				// do nothing if snackbar is already displayed
				if ( displayed.current.includes ( key ) ) return;

				// display snackbar using notistack
				enqueueSnackbar ( message, {
					key,
					...options,
					onClose: ( event, reason, myKey ) => {
						if ( options.onClose )
						{
							options.onClose ( event, reason, myKey );
						}
					},
					onExited: ( event, myKey ) => {
						// remove this snackbar from redux store
						dispatch ( actions.removeSnackbar ( myKey ) );
						removeDisplayed ( myKey );
					}
				} );

				// keep track of snackbars that we've displayed
				storeDisplayed ( key );
			} );
		},
		[ snakbars, closeSnackbar, enqueueSnackbar, dispatch ]
	);

	return null;
};
