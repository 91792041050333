import 'reactotron';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { theme, MapComponentProvider } from 'mlk-ui-kit';
import store from 'reduxStore';
import App from './App';

if ( process.env.NODE_ENV === 'development' ) console.log ( theme );

const render = Component => ReactDOM.render (
	<ThemeProvider {...{ theme }}>
		<Provider {...{ store }}>
			<SnackbarProvider>
				<MapComponentProvider>
					<Component />
				</MapComponentProvider>
			</SnackbarProvider>
		</Provider>
	</ThemeProvider>,
	document.getElementById ( 'root' )
);

render ( App );

if ( module.hot )
{
	module.hot.accept (
		'./App',
		() => render (
			require ( './App' ).default // Next App
		)
	);
}
