import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { MainComponent, MainHeader, MainContent, MainDrawer, HeaderLogo, HeaderProfile } from 'mlk-ui-kit';
import { Calendar, Drivers, MapComponent } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions';


export default React.memo ( Main );

function Main ()
{
	const dispatch = useDispatch(),
		name = useSelector ( st => st.user.name || '' ),
		logout = useCallback (
			() => dispatch ( actions.logout() ),
			[ dispatch ]
		);

	return (
		<MainComponent>
			<MainHeader>
				<Grid container alignItems="center">
					<HeaderLogo>MLK LOGISTIC</HeaderLogo>
					<Calendar />
				</Grid>
				<HeaderProfile {...{ name, logout }} />
			</MainHeader>
			<MainContent>
				<MapComponent />
			</MainContent>
			<MainDrawer>
				<Drivers />
			</MainDrawer>
		</MainComponent>
	);
}
