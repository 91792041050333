import './App.css';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Auth, Main } from 'routes';
import { Snackbars } from 'components';
import { useSelector, shallowEqual } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


export default React.memo ( App );

function App ()
{
	const init = useSelector ( st => st.other.init ),
		{ authorized, id } = useSelector ( st => st.user, shallowEqual );

	if ( !init ) return null;

	return (
		<Router>
			<CssBaseline />
			<Switch>
				{ ( !authorized || id === false ) ?
					<Route path="/" component={ Auth } />
					:
					<Route path="/" component={ Main } />
				}
			</Switch>
			<Snackbars />
		</Router>
	);
}
